const initialState = {
  ended: false,
  player: null,
  playbackRate: 1,
  muted: true,
}

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VIDEO_ENDED':
      return {
        ...state,
        ended: action.payload,
      }
    case 'SET_CURRENT_VIDEO_TIME':
      return {
        ...state,
        currentTime: action.payload,
      }
    case 'SET_VIDEO_PLAYER':
      return {
        ...state,
        player: action.payload,
      }
    case 'SET_PLAYBACK_RATE':
      return {
        ...state,
        playbackRate: action.payload,
      }
    case 'SET_MUTED':
      return {
        ...state,
        muted: action.payload,
      }
    default:
      return state
  }
}

export default videoReducer
