import React, { useState } from 'react'
import { setSessionPassword } from '../utils/utils'

const PasswordProtect = () => {
  const [password, setPassword] = useState('')

  const onSubmit = (event) => {
    event.preventDefault()
    setSessionPassword(password)
    window.location.reload(); // eslint-disable-line
  }

  return (
    <div className="h-screen flex items-center justify-center flex-col p-3 max-w-sm mx-auto">
      <div className="text-center w-full">
      <h3 className="font-maison-meue-book text-base mb-2">
        Brageteatret presenterer
      </h3>
      <h2 className="font-brand text-white text-4xl uppercase text-center mb-20 sm-text-3xl">
        SKUDDSIKKER VEST
      </h2>
      <div>
        <h2 className="capitalize text-sm text-center font-normal mb-3">Oppgi passord</h2>

        <form onSubmit={onSubmit}>
          <input
            name="password"
            type="password"
            placeholder="Passord"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className="mb-4 px-4 py-6 bg-black block w-full border-white border-2 focus:ring-0 focus:border-white"
          />

          <button
            type="submit"
            className="bg-brand px-5 py-5  text-2xl text-black uppercase font-demi  w-full no-underline focus:outline-none"
          >
            Gå inn
          </button>
        </form>
        </div>
        </div>
    </div>
  )
}

export default PasswordProtect
